<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="文章标题:">
                    <el-input v-model="queryParams.title" placeholder="请输入文章标题" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="source_id" label="来源id" width="80" align="center"></el-table-column>
                <el-table-column prop="uid" label="用户id" width="80" align="center"></el-table-column>
                <el-table-column prop="title" label="文章标题" width="150" align="center"></el-table-column>
                <el-table-column prop="username" label="用户名" width="150" align="center"></el-table-column>
                <el-table-column label="用户头像" align="center" width="80" prop="avatar">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px;" fit="cover" :class="`img1column${scope.$index}`" :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="封面图" align="center" width="80" prop="thumb">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img2')" style="height:23px;width:30px;" fit="cover" :class="`img2column${scope.$index}`" :src="scope.row.thumb" :preview-src-list="[scope.row.thumb]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="tag_ids" label="标签id" align="center" width="80"></el-table-column> -->
                <el-table-column prop="name" label="关联栏目" align="center" width="150"></el-table-column>
                <el-table-column prop="ctime" label="发布时间" align="center" width="150"></el-table-column>
                <el-table-column label="文章内容" prop="source_cntent" align="center" width="150"></el-table-column>
                <el-table-column label="是否置顶" prop="is_top" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.is_top === 1">否</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_top === 2">是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否加精" prop="is_tperfect" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.is_tperfect === 1">否</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_tperfect === 2">是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="来源" prop="ty" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty === 1">抖音</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import { request } from '@/common/request';
import { deal } from '@/common/main';
let loads = new loadEvents();
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
    },
    data() {
        return {
            defaultImg: require('../../assets/img/zhan.jpeg'),
            header: {},
            menuList: ['社区', '文章管理'],
            queryParams: {
                page: 1,
                pagesize: 10
            },
            loading: false,
            pageInfo: {},
            confirmVisible: false,
            confirmContent: '',
            title: '加载中',
        }
    },
    created() {
        this.loadData();
    },
    watch: {
        $route(to, from) {
            let ArticleEdit = from.fullPath.indexOf('/community-LettresEdit')
            let ArticleAdd = from.fullPath.indexOf('/community-LettresAdd')
            if (ArticleEdit !== -1 || ArticleAdd !== -1) {
                this.loadData()
            }
        }
    },

    //图片上传
    methods: {
        // 查看大图
        showBigImg(index, node) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`${node}column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/community/article/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.stime = deal.timestamp(val.stime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        //新增
        add() {
            this.$router.push({ path: '/community-LettresAdd' })
        },
        //编辑
        edit(row) {
            this.$router.push({ path: '/community-LettresEdit', query: { id: row.id } })
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },

        // 确定下线
        stateData() {
            request.post('/community/article/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 关闭tag
        handleClose(index) {
            this.tagList.splice(index, 1);
            this.state = ''
        },
        showtag() {
            this.tagVisible = true;
        },
        queryTagSearch(queryString, cb) {
            let results = []
            this.queryParams.name = queryString
            request.get('/common/tag/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    if (ret.data.list.length > 0) {
                        setTimeout(() => {
                            for (let i of ret.data.list) {
                                i.value = i.name;  //将想要展示的数据作为value
                            }
                            results = ret.data.list
                            cb(results);
                        }, 200);
                    } else {
                        request.post('/common/tag/add', { name: queryString, state: 2 }).then(ret => {
                            if (ret.code == 1) {
                                this.queryTagSearch(queryString, cb);
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    }

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 新增tag
        handleTagSelect(item) {
            this.tagVisible = false;
            this.tagList.push(item)
            let obj = {};
            let newArr = this.tagList.reduce((cur, next) => {
                obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
                return cur;
            }, [])
            this.tagList = newArr
            this.tag = null
        },
    }
};
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

.span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.dialog_box .logo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 150px;
    height: 200px;
    line-height: 200px;
}
.dialog_box .logo {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

.dialog_box .el-button {
    margin-left: 20px;
}
</style>